<template>
  <div>
    <el-container style="height: 100vh;">
      <el-aside :width="asideWidth" style="overflow-x: hidden;">
        <div
            style="padding: 20px 0;text-align: center;color: #ececec;white-space: nowrap;font-weight: bold;">
          <span>
            视频剪辑管理系统
          </span>
        </div>
        <el-menu
            :default-active="defaultActive"
            router
            background-color="#334157"
            text-color="#fff"
            active-text-color="#409eff"
            unique-opened
            @select="menuSelect">
            <el-menu-item index="user">
                <i class="el-icon-user-solid"></i>
                <span slot="title">用户管理</span>
            </el-menu-item>
            <el-menu-item index="manager">
                <i class="el-icon-user-solid"></i>
                <span slot="title">系统管理员管理</span>
            </el-menu-item>
            <el-menu-item index="invitation">
              <i class="el-icon-user-solid"></i>
              <span slot="title">邀请码管理</span>
            </el-menu-item>
            <el-menu-item index="dict">
                <i class="el-icon-user-solid"></i>
                <span slot="title">字典管理</span>
            </el-menu-item>
            <el-menu-item index="background">
                <i class="el-icon-user-solid"></i>
                <span slot="title">背景图管理</span>
            </el-menu-item>
            <el-menu-item index="phone">
              <i class="el-icon-user-solid"></i>
              <span slot="title">小精灵账号管理</span>
            </el-menu-item>
            <el-menu-item index="teachText">
              <i class="el-icon-user-solid"></i>
              <span slot="title">教学管理</span>
            </el-menu-item>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header style="text-align: right; font-size: 18px;color: #606266;  cursor: pointer;position: relative;">
          <i class="el-icon-s-operation wr-15" style="position:absolute;left: 25px;line-height: 60px;"
             @click="slideToggle"></i>
          <span style="position:absolute;left: 60px;line-height: 60px;font-size: 16px;">
                当前位置：{{ nav }}
          </span>
          <i class="el-icon-menu wr-15" @click="drawer = true"></i>
          <i class="el-icon-refresh-right wr-15" @click="reload"></i>
          <el-dropdown style="font-size: 16px;">
            <span class="el-dropdown-link" style="color: #606266;">
              <i class="el-icon-user"></i>
              {{ name }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                个人资料
              </el-dropdown-item>
              <el-dropdown-item divided @click.native="logout">
                退出系统
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-header>
        <el-main style="overflow-x: hidden;padding:15px;">
          <transition name="slide-fade">
            <router-view style="overflow: hidden;"/>
          </transition>
        </el-main>
      </el-container>
    </el-container>
    <el-drawer
        :visible.sync="drawer"
        direction="btt"
        size="250px"
        :show-close="false"
        title="快捷菜单"
        :with-header="true">
      <IMenu/>
    </el-drawer>
  </div>
</template>
<script>
import IMenu from "@/components/menu/IMenu";

export default {
  data() {
    return {
      defaultActive: "control",
      asideWidth: "230px",//菜单宽度
      drawer: false,
      nav: "用户管理",
      navData: {
        user: "用户管理",
        manager: "系统用户管理",
        invitation: "邀请码管理",
        dict: "字典管理",
        background: "背景图管理",
        phone: "手机号",
        teachText: "教学管理"
      },
      name: JSON.parse(sessionStorage.getItem("user")).name,
    }
  },
  components: {
    IMenu
  },
  mounted() {

  },
  methods: {
    logout() {
      this.$confirm("确定退出？", '提示', {type: "warning"}).then(() => {
        sessionStorage.clear()
        this.$router.push("/login")
        this.$message({
          type: "success",
          message: "退出成功!"
        });
      })
    },
    menuSelect(a, arr) {
      console.log(this.navData[a])
      this.nav = this.navData[a] || ""
    },
    reload() {
      this.$router.go(this.$route.fullPath)
    },
    slideToggle() {
      this.asideWidth = this.asideWidth !== "0" ? "0" : "230px";
    }
  }
};
</script>
<style lang="scss" scoped>
.el-header {
  background-color: #ffffff;
  line-height: 60px;
  box-shadow: 0 2px 10px #c4c4c4;
  z-index: 1;
  user-select: none;
}

.el-aside {
  color: #333;
  box-shadow: 3px 0 10px #c4c4c4;
  z-index: 2;
  background-color: #334157;
  border-right: none;
  transition: width 0.5s;
}

.el-container {
  width: 100%;
}

.el-menu {
  border-right: none;
}

.el-menu-item {
}
.el-menu--inline .el-menu-item{
  background-color: #1f2d3d!important;
}
.is-active {
  background: #273344 !important;
}

.wr-15 {
  margin-right: 15px;
}

.slide-fade-enter-active {
  -webkit-transition: all .5s ease;
}

.slide-fade-leave-active {
  -webkit-transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave {
  -webkit-transform: translateX(100px);
  opacity: 0;
}

</style>

