<template>
  <!--快捷方块菜单-->
  <div class="menu-box">
    <div class="menu-box-item">
      <div class="menu-box-item-icon">
        <i class="el-icon-chat-dot-square" style="color: #00a1ff;"></i>
      </div>
      <div class="menu-box-item-text">
        消息通知
      </div>
    </div>
    <div class="menu-box-item">
      <div class="menu-box-item-icon">
        <i class="el-icon-s-custom" style="color: #000000;"></i>
      </div>
      <div class="menu-box-item-text">
        用户管理
      </div>
    </div>
    <div class="menu-box-item">
      <div class="menu-box-item-icon">
        <i class="el-icon-picture" style="color: #ff5a8e;"></i>
      </div>
      <div class="menu-box-item-text">
        图床管理
      </div>
    </div>
    <div class="menu-box-item">
      <div class="menu-box-item-icon">
        <i class="el-icon-s-tools" style="color: #790dff;"></i>
      </div>
      <div class="menu-box-item-text">
        系统设置
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IMenu"
}
</script>

<style scoped lang="scss">

.menu-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
}

.menu-box-item {
  width: 80px;
  margin: 15px;

  .menu-box-item-icon {
    height: 80px;
    border: 1px solid #d9d9d9;

    i {
      cursor: pointer;
      line-height: 80px;
      text-align: center;
      font-size: 50px;
      width: 100%;
      height: 100%;

      &:hover {
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
      }
    }
  }

  .menu-box-item-text {
    height: 30px;
    font-size: 12px;
    margin-top: 5px;
    text-align: center;
  }

}

</style>